    import jQuery from 'jquery';
    /* Action Sheet Start */
    let ActionSheetButtonStyle ={};
    ActionSheetButtonStyle["Default"] = "DEFAULT";
    ActionSheetButtonStyle["Destructive"] = "DESTRUCTIVE";
    ActionSheetButtonStyle["Cancel"] = "CANCEL";
    const ActionSheetOptionStyle = ActionSheetButtonStyle;
    class ActionSheetWeb{
        constructor() {
            this.ActionSheetButtonStyle ={};
            this.ActionSheetButtonStyle["Default"] = "DEFAULT";
            this.ActionSheetButtonStyle["Destructive"] = "DESTRUCTIVE";
            this.ActionSheetButtonStyle["Cancel"] = "CANCEL";
        }
        async showActions(options) {
            return new Promise((resolve, _reject) => {
                let actionSheet = document.querySelector('pwa-action-sheet');
                if (!actionSheet) {
                    actionSheet = document.createElement('pwa-action-sheet');
                    document.body.appendChild(actionSheet);
                }
                actionSheet.header = options.title;
                actionSheet.cancelable = false;
                actionSheet.options = options.options;
                actionSheet.addEventListener('onSelection', async (e) => {
                    const selection = e.detail;
                    resolve({
                        index: selection,
                    });
                });
            });
        }
    }

    /* Action Sheet End */

    /* Filesystem Start */

    function resolve(path) {
        const posix = path.split('/').filter(item => item !== '.');
        const newPosix = [];
        posix.forEach(item => {
            if (item === '..' &&
                newPosix.length > 0 &&
                newPosix[newPosix.length - 1] !== '..') {
                newPosix.pop();
            }
            else {
                newPosix.push(item);
            }
        });
        return newPosix.join('/');
    }
    function isPathParent(parent, children) {
        parent = resolve(parent);
        children = resolve(children);
        const pathsA = parent.split('/');
        const pathsB = children.split('/');
        return (parent !== children &&
            pathsA.every((value, index) => value === pathsB[index]));
    }

    
    let Directory={};
    Directory["Documents"] = "DOCUMENTS";
    Directory["Data"] = "DATA";
    Directory["Library"] = "LIBRARY";
    Directory["Cache"] = "CACHE";
    Directory["External"] = "EXTERNAL";
    Directory["ExternalStorage"] = "EXTERNAL_STORAGE";

    let Encoding = {};
    Encoding["UTF8"] = "utf8";
    Encoding["ASCII"] = "ascii";
    Encoding["UTF16"] = "utf16";

    class FilesystemWeb{
        constructor() {
            this.DB_VERSION = 1;
            this.DB_NAME = 'Disc';
            this._writeCmds = ['add', 'put', 'delete'];
        }
        async initDb() {
            if (this._db !== undefined) {
                return this._db;
            }
            if (!('indexedDB' in window)) {
                throw this.unavailable("This browser doesn't support IndexedDB");
            }
            return new Promise((resolve, reject) => {
                const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);
                request.onupgradeneeded = FilesystemWeb.doUpgrade;
                request.onsuccess = () => {
                    this._db = request.result;
                    resolve(request.result);
                };
                request.onerror = () => reject(request.error);
                request.onblocked = () => {
                    console.warn('db blocked');
                };
            });
        }
        static doUpgrade(event) {
            const eventTarget = event.target;
            const db = eventTarget.result;
            switch (event.oldVersion) {
                case 0:
                case 1:
                default: {
                    if (db.objectStoreNames.contains('FileStorage')) {
                        db.deleteObjectStore('FileStorage');
                    }
                    const store = db.createObjectStore('FileStorage', { keyPath: 'path' });
                    store.createIndex('by_folder', 'folder');
                }
            }
        }
        async dbRequest(cmd, args) {
            const readFlag = this._writeCmds.indexOf(cmd) !== -1 ? 'readwrite' : 'readonly';
            return this.initDb().then((conn) => {
                return new Promise((resolve, reject) => {
                    const tx = conn.transaction(['FileStorage'], readFlag);
                    const store = tx.objectStore('FileStorage');
                    const req = store[cmd](...args);
                    req.onsuccess = () => resolve(req.result);
                    req.onerror = () => reject(req.error);
                });
            });
        }
        async dbIndexRequest(indexName, cmd, args) {
            const readFlag = this._writeCmds.indexOf(cmd) !== -1 ? 'readwrite' : 'readonly';
            return this.initDb().then((conn) => {
                return new Promise((resolve, reject) => {
                    const tx = conn.transaction(['FileStorage'], readFlag);
                    const store = tx.objectStore('FileStorage');
                    const index = store.index(indexName);
                    const req = index[cmd](...args);
                    req.onsuccess = () => resolve(req.result);
                    req.onerror = () => reject(req.error);
                });
            });
        }
        getPath(directory, uriPath) {
            const cleanedUriPath = uriPath !== undefined ? uriPath.replace(/^[/]+|[/]+$/g, '') : '';
            let fsPath = '';
            if (directory !== undefined)
                fsPath += '/' + directory;
            if (uriPath !== '')
                fsPath += '/' + cleanedUriPath;
            return fsPath;
        }
        async clear() {
            const conn = await this.initDb();
            const tx = conn.transaction(['FileStorage'], 'readwrite');
            const store = tx.objectStore('FileStorage');
            store.clear();
        }
        /**
         * Read a file from disk
         * @param options options for the file read
         * @return a promise that resolves with the read file data result
         */
        async readFile(options) {
            const path = this.getPath(options.directory, options.path);
            // const encoding = options.encoding;
            const entry = (await this.dbRequest('get', [path]));
            if (entry === undefined)
                throw Error('File does not exist.');
            return { data: entry.content ? entry.content : '' };
        }
        /**
         * Write a file to disk in the specified location on device
         * @param options options for the file write
         * @return a promise that resolves with the file write result
         */
        async writeFile(options) {
            const path = this.getPath(options.directory, options.path);
            const data = options.data;
            const doRecursive = options.recursive;
            const occupiedEntry = (await this.dbRequest('get', [path]));
            if (occupiedEntry && occupiedEntry.type === 'directory')
                throw Error('The supplied path is a directory.');
            const encoding = options.encoding;
            const parentPath = path.substr(0, path.lastIndexOf('/'));
            const parentEntry = (await this.dbRequest('get', [parentPath]));
            if (parentEntry === undefined) {
                const subDirIndex = parentPath.indexOf('/', 1);
                if (subDirIndex !== -1) {
                    const parentArgPath = parentPath.substr(subDirIndex);
                    await this.mkdir({
                        path: parentArgPath,
                        directory: options.directory,
                        recursive: doRecursive,
                    });
                }
            }
            const now = Date.now();
            const pathObj = {
                path: path,
                folder: parentPath,
                type: 'file',
                size: data.length,
                ctime: now,
                mtime: now,
                content: !encoding && data.indexOf(',') >= 0 ? data.split(',')[1] : data,
            };
            await this.dbRequest('put', [pathObj]);
            return {
                uri: pathObj.path,
            };
        }
        /**
         * Append to a file on disk in the specified location on device
         * @param options options for the file append
         * @return a promise that resolves with the file write result
         */
        async appendFile(options) {
            const path = this.getPath(options.directory, options.path);
            let data = options.data;
            // const encoding = options.encoding;
            const parentPath = path.substr(0, path.lastIndexOf('/'));
            const now = Date.now();
            let ctime = now;
            const occupiedEntry = (await this.dbRequest('get', [path]));
            if (occupiedEntry && occupiedEntry.type === 'directory')
                throw Error('The supplied path is a directory.');
            const parentEntry = (await this.dbRequest('get', [parentPath]));
            if (parentEntry === undefined) {
                const subDirIndex = parentPath.indexOf('/', 1);
                if (subDirIndex !== -1) {
                    const parentArgPath = parentPath.substr(subDirIndex);
                    await this.mkdir({
                        path: parentArgPath,
                        directory: options.directory,
                        recursive: true,
                    });
                }
            }
            if (occupiedEntry !== undefined) {
                data = occupiedEntry.content + data;
                ctime = occupiedEntry.ctime;
            }
            const pathObj = {
                path: path,
                folder: parentPath,
                type: 'file',
                size: data.length,
                ctime: ctime,
                mtime: now,
                content: data,
            };
            await this.dbRequest('put', [pathObj]);
        }
        /**
         * Delete a file from disk
         * @param options options for the file delete
         * @return a promise that resolves with the deleted file data result
         */
        async deleteFile(options) {
            const path = this.getPath(options.directory, options.path);
            const entry = (await this.dbRequest('get', [path]));
            if (entry === undefined)
                throw Error('File does not exist.');
            const entries = await this.dbIndexRequest('by_folder', 'getAllKeys', [
                IDBKeyRange.only(path),
            ]);
            if (entries.length !== 0)
                throw Error('Folder is not empty.');
            await this.dbRequest('delete', [path]);
        }
        /**
         * Create a directory.
         * @param options options for the mkdir
         * @return a promise that resolves with the mkdir result
         */
        async mkdir(options) {
            const path = this.getPath(options.directory, options.path);
            const doRecursive = options.recursive;
            const parentPath = path.substr(0, path.lastIndexOf('/'));
            const depth = (path.match(/\//g) || []).length;
            const parentEntry = (await this.dbRequest('get', [parentPath]));
            const occupiedEntry = (await this.dbRequest('get', [path]));
            if (depth === 1)
                throw Error('Cannot create Root directory');
            if (occupiedEntry !== undefined)
                throw Error('Current directory does already exist.');
            if (!doRecursive && depth !== 2 && parentEntry === undefined)
                throw Error('Parent directory must exist');
            if (doRecursive && depth !== 2 && parentEntry === undefined) {
                const parentArgPath = parentPath.substr(parentPath.indexOf('/', 1));
                await this.mkdir({
                    path: parentArgPath,
                    directory: options.directory,
                    recursive: doRecursive,
                });
            }
            const now = Date.now();
            const pathObj = {
                path: path,
                folder: parentPath,
                type: 'directory',
                size: 0,
                ctime: now,
                mtime: now,
            };
            await this.dbRequest('put', [pathObj]);
        }
        /**
         * Remove a directory
         * @param options the options for the directory remove
         */
        async rmdir(options) {
            const { path, directory, recursive } = options;
            const fullPath = this.getPath(directory, path);
            const entry = (await this.dbRequest('get', [fullPath]));
            if (entry === undefined)
                throw Error('Folder does not exist.');
            if (entry.type !== 'directory')
                throw Error('Requested path is not a directory');
            const readDirResult = await this.readdir({ path, directory });
            if (readDirResult.files.length !== 0 && !recursive)
                throw Error('Folder is not empty');
            for (const entry of readDirResult.files) {
                const entryPath = `${path}/${entry}`;
                const entryObj = await this.stat({ path: entryPath, directory });
                if (entryObj.type === 'file') {
                    await this.deleteFile({ path: entryPath, directory });
                }
                else {
                    await this.rmdir({ path: entryPath, directory, recursive });
                }
            }
            await this.dbRequest('delete', [fullPath]);
        }
        /**
         * Return a list of files from the directory (not recursive)
         * @param options the options for the readdir operation
         * @return a promise that resolves with the readdir directory listing result
         */
        async readdir(options) {
            const path = this.getPath(options.directory, options.path);
            const entry = (await this.dbRequest('get', [path]));
            if (options.path !== '' && entry === undefined)
                throw Error('Folder does not exist.');
            const entries = await this.dbIndexRequest('by_folder', 'getAllKeys', [IDBKeyRange.only(path)]);
            const names = entries.map(e => {
                return e.substring(path.length + 1);
            });
            return { files: names };
        }
        /**
         * Return full File URI for a path and directory
         * @param options the options for the stat operation
         * @return a promise that resolves with the file stat result
         */
        async getUri(options) {
            const path = this.getPath(options.directory, options.path);
            let entry = (await this.dbRequest('get', [path]));
            if (entry === undefined) {
                entry = (await this.dbRequest('get', [path + '/']));
            }
            return {
                uri: (entry === null || entry === void 0 ? void 0 : entry.path) || path,
            };
        }
        /**
         * Return data about a file
         * @param options the options for the stat operation
         * @return a promise that resolves with the file stat result
         */
        async stat(options) {
            const path = this.getPath(options.directory, options.path);
            let entry = (await this.dbRequest('get', [path]));
            if (entry === undefined) {
                entry = (await this.dbRequest('get', [path + '/']));
            }
            if (entry === undefined)
                throw Error('Entry does not exist.');
            return {
                type: entry.type,
                size: entry.size,
                ctime: entry.ctime,
                mtime: entry.mtime,
                uri: entry.path,
            };
        }
        /**
         * Rename a file or directory
         * @param options the options for the rename operation
         * @return a promise that resolves with the rename result
         */
        async rename(options) {
            return this._copy(options, true);
        }
        /**
         * Copy a file or directory
         * @param options the options for the copy operation
         * @return a promise that resolves with the copy result
         */
        async copy(options) {
            return this._copy(options, false);
        }
        async requestPermissions() {
            return { publicStorage: 'granted' };
        }
        async checkPermissions() {
            return { publicStorage: 'granted' };
        }
        /**
         * Function that can perform a copy or a rename
         * @param options the options for the rename operation
         * @param doRename whether to perform a rename or copy operation
         * @return a promise that resolves with the result
         */
        async _copy(options, doRename = false) {
            let { toDirectory } = options;
            const { to, from, directory: fromDirectory } = options;
            if (!to || !from) {
                throw Error('Both to and from must be provided');
            }
            // If no "to" directory is provided, use the "from" directory
            if (!toDirectory) {
                toDirectory = fromDirectory;
            }
            const fromPath = this.getPath(fromDirectory, from);
            const toPath = this.getPath(toDirectory, to);
            // Test that the "to" and "from" locations are different
            if (fromPath === toPath) {
                return;
            }
            if (isPathParent(fromPath, toPath)) {
                throw Error('To path cannot contain the from path');
            }
            // Check the state of the "to" location
            let toObj;
            try {
                toObj = await this.stat({
                    path: to,
                    directory: toDirectory,
                });
            }
            catch (e) {
                // To location does not exist, ensure the directory containing "to" location exists and is a directory
                const toPathComponents = to.split('/');
                toPathComponents.pop();
                const toPath = toPathComponents.join('/');
                // Check the containing directory of the "to" location exists
                if (toPathComponents.length > 0) {
                    const toParentDirectory = await this.stat({
                        path: toPath,
                        directory: toDirectory,
                    });
                    if (toParentDirectory.type !== 'directory') {
                        throw new Error('Parent directory of the to path is a file');
                    }
                }
            }
            // Cannot overwrite a directory
            if (toObj && toObj.type === 'directory') {
                throw new Error('Cannot overwrite a directory with a file');
            }
            // Ensure the "from" object exists
            const fromObj = await this.stat({
                path: from,
                directory: fromDirectory,
            });
            // Set the mtime/ctime of the supplied path
            const updateTime = async (path, ctime, mtime) => {
                const fullPath = this.getPath(toDirectory, path);
                const entry = (await this.dbRequest('get', [fullPath]));
                entry.ctime = ctime;
                entry.mtime = mtime;
                await this.dbRequest('put', [entry]);
            };
            const ctime = fromObj.ctime ? fromObj.ctime : Date.now();
            switch (fromObj.type) {
                // The "from" object is a file
                case 'file': {
                    // Read the file
                    const file = await this.readFile({
                        path: from,
                        directory: fromDirectory,
                    });
                    // Optionally remove the file
                    if (doRename) {
                        await this.deleteFile({
                            path: from,
                            directory: fromDirectory,
                        });
                    }
                    // Write the file to the new location
                    await this.writeFile({
                        path: to,
                        directory: toDirectory,
                        data: file.data,
                    });
                    // Copy the mtime/ctime of a renamed file
                    if (doRename) {
                        await updateTime(to, ctime, fromObj.mtime);
                    }
                    // Resolve promise
                    return;
                }
                case 'directory': {
                    if (toObj) {
                        throw Error('Cannot move a directory over an existing object');
                    }
                    try {
                        // Create the to directory
                        await this.mkdir({
                            path: to,
                            directory: toDirectory,
                            recursive: false,
                        });
                        // Copy the mtime/ctime of a renamed directory
                        if (doRename) {
                            await updateTime(to, ctime, fromObj.mtime);
                        }
                    }
                    catch (e) {
                        // ignore
                    }
                    // Iterate over the contents of the from location
                    const contents = (await this.readdir({
                        path: from,
                        directory: fromDirectory,
                    })).files;
                    for (const filename of contents) {
                        // Move item from the from directory to the to directory
                        await this._copy({
                            from: `${from}/${filename}`,
                            to: `${to}/${filename}`,
                            directory: fromDirectory,
                            toDirectory,
                        }, doRename);
                    }
                    // Optionally remove the original from directory
                    if (doRename) {
                        await this.rmdir({
                            path: from,
                            directory: fromDirectory
                        });
                    }
                }
            }
        }
    }
    /* End Filesystem */

    /* Device plugin */
    class Device{
        constructor() {
        }
        getUid() {
            if (typeof window !== 'undefined') {
                let uid = window.localStorage.getItem('_capuid');
                if (uid) {
                    return uid;
                }
                uid = this.uuid4();
                window.localStorage.setItem('_capuid', uid);
                return uid;
            }
            return this.uuid4();
        }
    
        async getId() {
            return {
                uuid: this.getUid(),
            };
        }
    
        parseUa(ua) {
            const uaFields = {};
            const start = ua.indexOf('(') + 1;
            let end = ua.indexOf(') AppleWebKit');
            if (ua.indexOf(') Gecko') !== -1) {
                end = ua.indexOf(') Gecko');
            }
            const fields = ua.substring(start, end);
            if (ua.indexOf('Android') !== -1) {
                const tmpFields = fields.replace('; wv', '').split('; ').pop();
                if (tmpFields) {
                    uaFields.model = tmpFields.split(' Build')[0];
                }
                uaFields.osVersion = fields.split('; ')[1];
            }
            else {
                uaFields.model = fields.split('; ')[0];
                if (typeof navigator !== 'undefined' && navigator.oscpu) {
                    uaFields.osVersion = navigator.oscpu;
                }
                else {
                    if (ua.indexOf('Windows') !== -1) {
                        uaFields.osVersion = fields;
                    }
                    else {
                        const tmpFields = fields.split('; ').pop();
                        if (tmpFields) {
                            const lastParts = tmpFields
                                .replace(' like Mac OS X', '')
                                .split(' ');
                            uaFields.osVersion = lastParts[lastParts.length - 1].replace(/_/g, '.');
                        }
                    }
                }
            }
            if (/android/i.test(ua)) {
                uaFields.operatingSystem = 'android';
            }
            else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
                uaFields.operatingSystem = 'ios';
            }
            else if (/Win/.test(ua)) {
                uaFields.operatingSystem = 'windows';
            }
            else if (/Mac/i.test(ua)) {
                uaFields.operatingSystem = 'mac';
            }
            else {
                uaFields.operatingSystem = 'unknown';
            }
            // Check for browsers based on non-standard javascript apis, only not user agent
            const isFirefox = !!window.InstallTrigger;
            const isSafari = !!window.ApplePaySession;
            const isChrome = !!window.chrome;
            const isEdge = /Edg/.test(ua);
            const isFirefoxIOS = /FxiOS/.test(ua);
            const isChromeIOS = /CriOS/.test(ua);
            const isEdgeIOS = /EdgiOS/.test(ua);
            // FF and Edge User Agents both end with "/MAJOR.MINOR"
            if (isSafari ||
                (isChrome && !isEdge) ||
                isFirefoxIOS ||
                isChromeIOS ||
                isEdgeIOS) {
                // Safari version comes as     "... Version/MAJOR.MINOR ..."
                // Chrome version comes as     "... Chrome/MAJOR.MINOR ..."
                // FirefoxIOS version comes as "... FxiOS/MAJOR.MINOR ..."
                // ChromeIOS version comes as  "... CriOS/MAJOR.MINOR ..."
                let searchWord;
                if (isFirefoxIOS) {
                    searchWord = 'FxiOS';
                }
                else if (isChromeIOS) {
                    searchWord = 'CriOS';
                }
                else if (isEdgeIOS) {
                    searchWord = 'EdgiOS';
                }
                else if (isSafari) {
                    searchWord = 'Version';
                }
                else {
                    searchWord = 'Chrome';
                }
                const words = ua.split(' ');
                for (const word of words) {
                    if (word.includes(searchWord)) {
                        const version = word.split('/')[1];
                        uaFields.browserVersion = version;
                    }
                }
            }
            else if (isFirefox || isEdge) {
                const reverseUA = ua.split('').reverse().join('');
                const reverseVersion = reverseUA.split('/')[0];
                const version = reverseVersion.split('').reverse().join('');
                uaFields.browserVersion = version;
            }
            return uaFields;
        }
    
        uuid4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
       
        async getDeviceInfo() {
            if (typeof navigator === 'undefined' || !navigator.userAgent) {
                throw this.unavailable('Device API not available in this browser');
            }
            const ua = await navigator.userAgent;
            const uaFields =await this.parseUa(ua);
            return {
                model: uaFields.model,
                platform: 'web',
                operatingSystem: uaFields.operatingSystem,
                osVersion: uaFields.osVersion,
                manufacturer: navigator.vendor,
                isVirtual: false,
                webViewVersion: uaFields.browserVersion,
            };
        }
    
        async getBatteryInfo() {
            if (typeof navigator === 'undefined' || !navigator.getBattery) {
                throw this.unavailable('Device API not available in this browser');
            }
            let battery = {};
            try {
                battery = await navigator.getBattery();
            }
            catch (e) {
                // Let it fail, we don't care
            }
            return {
                batteryLevel: battery.level,
                isCharging: battery.charging,
            };
        }
    
        async getLanguageCode() {
            return {
                value: navigator.language,
            };
        }
    }
    /* Device plugin end */

    class statusBar{
        constructor() {
            this._supported = null; // set to null so we can check first time
           
        }
        isSupported () {
            // if not checked before, run check
            if (this._supported === null) {
                var viewMan = window.UI.ViewManagement;
                this._supported = viewMan.StatusBar && viewMan.StatusBar.getForCurrentView;
            }
            return this._supported;
        }

        getViewStatusBar () {
            if (!this.isSupported()) {
                throw new Error('Status bar is not supported');
            }
            return window.UI.ViewManagement.StatusBar.getForCurrentView();
        }

        hexToRgb (hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                }
                : null;
        }
        _ready=(win, fail) =>{
            if (this.isSupported()) {
                var statusBar = this.getViewStatusBar();
                win(statusBar.occludedRect.height !== 0);
            }
        }
        overlaysWebView=()=> {
            // not supported
        }

        styleDefault=()=> {
            // dark text ( to be used on a light background )
            if (this.isSupported()) {
                this.getViewStatusBar().foregroundColor = { a: 0, r: 0, g: 0, b: 0 };
            }
        }

        styleLightContent=()=> {
            // light text ( to be used on a dark background )
            if (this.isSupported()) {
                this.getViewStatusBar().foregroundColor = { a: 0, r: 255, g: 255, b: 255 };
            }
        }

        styleBlackTranslucent=()=>{
            // #88000000 ? Apple says to use lightContent instead
            return module.exports.styleLightContent();
        }

        styleBlackOpaque=()=> {
            // #FF000000 ? Apple says to use lightContent instead
            return module.exports.styleLightContent();
        }

        backgroundColorByHexString=(win, fail, args)=> {
            var rgb = this.hexToRgb(args[0]);
            if (this.isSupported()) {
                var statusBar = this.getViewStatusBar();
                statusBar.backgroundColor = { a: 0, r: rgb.r, g: rgb.g, b: rgb.b };
                statusBar.backgroundOpacity = 1;
            }
        }

        show=(win, fail)=> {
            // added support check so no error thrown, when calling this method
            if (this.isSupported()) {
                this.getViewStatusBar().showAsync().done(win, fail);
            }
        }

        hide=(win, fail)=> {
            // added support check so no error thrown, when calling this method
            if (this.isSupported()) {
                this.getViewStatusBar().hideAsync().done(win, fail);
            }
        }
    }

    
  
    
(function ($) {
    "use strict";	
	/* Secure Storage Plugin */

    var DB_STATE_INIT, DB_STATE_OPEN, READ_ONLY_REGEX, SQLiteFactory, SQLitePlugin, SQLitePluginTransaction, SelfTest, argsArray, dblocations, iosLocationMap, newSQLError, nextTick, root, txLocks;

    root = this;
  
    READ_ONLY_REGEX = /^(\s|;)*(?:alter|create|delete|drop|insert|reindex|replace|update)/i;
  
    DB_STATE_INIT = "INIT";
  
    DB_STATE_OPEN = "OPEN";
  
    txLocks = {};
  
    newSQLError = function(error, code) {
      var sqlError;
      sqlError = error;
      if (!code) {
        code = 0;
      }
      if (!sqlError) {
        sqlError = new Error("a plugin had an error but provided no response");
        sqlError.code = code;
      }
      if (typeof sqlError === "string") {
        sqlError = new Error(error);
        sqlError.code = code;
      }
      if (!sqlError.code && sqlError.message) {
        sqlError.code = code;
      }
      if (!sqlError.code && !sqlError.message) {
        sqlError = new Error("an unknown error was returned: " + JSON.stringify(sqlError));
        sqlError.code = code;
      }
      return sqlError;
    };
  
    nextTick = window.setImmediate || function(fun) {
      window.setTimeout(fun, 0);
    };
  
  
    argsArray = function(fun) {
      return function() {
        var args, i, len;
        len = arguments.length;
        if (len) {
          args = [];
          i = -1;
          while (++i < len) {
            args[i] = arguments[i];
          }
          return fun.call(this, args);
        } else {
          return fun.call(this, []);
        }
      };
    };
  
  
    $.extend({
        metadata : {
            defaults : {
                type: 'class',
                name: 'metadata',
                cre: /({.*})/,
                single: 'metadata'
            },
            setType: function( type, name ){
                this.defaults.type = type;
                this.defaults.name = name;
            },
            get: function( elem, opts ){
                var settings = $.extend({},this.defaults,opts);
                // check for empty string in single property
                if ( !settings.single.length ) settings.single = 'metadata';
                
                var data = $.data(elem, settings.single);
                // returned cached data if it already exists
                if ( data ) return data;
                
                data = "{}";
                
                if ( settings.type == "class" ) {
                    var m = settings.cre.exec( elem.className );
                    if ( m )
                        data = m[1];
                } else if ( settings.type == "elem" ) {
                    if( !elem.getElementsByTagName )
                        return undefined;
                    var e = elem.getElementsByTagName(settings.name);
                    if ( e.length )
                        data = $.trim(e[0].innerHTML);
                } else if ( elem.getAttribute != undefined ) {
                    var attr = elem.getAttribute( settings.name );
                    if ( attr )
                        data = attr;
                }
                
                if ( data.indexOf( '{' ) <0 )
                data = "{" + data + "}";
                
                data = eval("(" + data + ")");
                
                $.data( elem, settings.single, data );
                return data;
            }
        }
    });

    $.fn.metadata = function( opts ){
        return $.metadata.get( this[0], opts );
    };
    // NOTE: Refer https://tutorialzine.github.io/pwa-photobooth/ for usage of camera
    /* let camera = (video, image, start_camera, controls, take_photo_btn, delete_photo_btn, download_photo_btn, error_message) => {
        if (!navigator.getMedia) {
            displayErrorMessage("Your browser doesn't have support for the navigator.getUserMedia interface.");
        }
        else {
            // Request the camera.
            navigator.getMedia(
                {
                    video: true
                },
                // Success Callback
                function (stream) {

                    // Create an object URL for the video stream and
                    // set it as src of our HTLM video element.
                    video.src = window.URL.createObjectURL(stream);

                    // Play the video element to start the stream.
                    video.play();
                    video.onplay = function () {
                        showVideo();
                    };

                },
                // Error Callback
                function (err) {
                    displayErrorMessage("There was an error with accessing the camera stream: " + err.name, err);
                }
            );
        }

        start_camera.addEventListener("click", function(e) {

            e.preventDefault();
    
            // Start video playback manually.
            video.play();
            showVideo();
    
        });
    
    
        take_photo_btn.addEventListener("click", function(e) {
    
            e.preventDefault();
    
            var snap = takeSnapshot();
    
            // Show image. 
            image.setAttribute('src', snap);
            image.classList.add("visible");
    
            // Enable delete and save buttons
            delete_photo_btn.classList.remove("disabled");
            download_photo_btn.classList.remove("disabled");
    
            // Set the href attribute of the download button to the snap url.
            download_photo_btn.href = snap;
    
            // Pause video playback of stream.
            video.pause();
    
        });
    
    
        delete_photo_btn.addEventListener("click", function(e) {
    
            e.preventDefault();
    
            // Hide image.
            image.setAttribute('src', "");
            image.classList.remove("visible");
    
            // Disable delete and save buttons
            delete_photo_btn.classList.add("disabled");
            download_photo_btn.classList.add("disabled");
    
            // Resume playback of stream.
            video.play();
    
        });
    
        function takeSnapshot() {
            // Here we're using a trick that involves a hidden canvas element.  
    
            var hidden_canvas = document.querySelector('canvas'),
                context = hidden_canvas.getContext('2d');
    
            var width = video.videoWidth,
                height = video.videoHeight;
    
            if (width && height) {
    
                // Setup a canvas with the same dimensions as the video.
                hidden_canvas.width = width;
                hidden_canvas.height = height;
    
                // Make a copy of the current frame in the video on the canvas.
                context.drawImage(video, 0, 0, width, height);
    
                // Turn the canvas image into a dataURL that can be used as a src for our photo.
                return hidden_canvas.toDataURL('image/png');
            }
        }
    
        function showVideo() {
            hideUI();
            video.classList.add("visible");
            controls.classList.add("visible");
        }
    
        function displayErrorMessage(error_msg, error) {
            error = error || "";
            if(error){
                console.error(error);
            }
    
            error_message.innerText = error_msg;
    
            hideUI();
            error_message.classList.add("visible");
        }
       
        function hideUI() {
            // Helper function for clearing the app UI.
    
            controls.classList.remove("visible");
            start_camera.classList.remove("visible");
            video.classList.remove("visible");
            snap.classList.remove("visible");
            error_message.classList.remove("visible");
        }
    }

    let readFile = (fileSelector) => {
        fileSelector.addEventListener('change', function() {
              
            var fr=new FileReader();
            fr.onload=function(){
                return fr.result;
            }
              
            fr.readAsArrayBuffer(this.files[0]);
        });
    }*/


    let getUserAgent = () => {
        let deviceDetails = {}
        if (typeof navigator === 'undefined' && !navigator.userAgent) {
            throw this.unavailable('User Agent not available in this browser');
        }
        return navigator.userAgent;
    }

    let getLocation = (success) => {
        let deviceDetails = {}
        if (typeof navigator === 'undefined' && !navigator.geolocation) {
            throw this.unavailable('Geolocation is not supported by this browser.');
        }
        return navigator.geolocation.getCurrentPosition(success);
    }
    //alert 
    let alert=(options)=> {
        window.alert(options.message);
    }

    //prompt
    let prompt=(options)=> {
        const val = window.prompt(options.message, options.inputText || '');
        return {
            value: val !== null ? val : '',
            cancelled: val === null,
        };
    }

    //confirm
    let confirm=(options)=> {
        const val = window.confirm(options.message);
        return {
            value: val,
        };
    }

    $.fn.easyNotify = function(options) {
  
        var settings = $.extend({
          title: "Notification",
          options: {
            body: "",
            icon: "",
            lang: 'pt-BR',
            onClose: "",
            onClick: "",
            onError: ""
          }
        }, options);
    
        this.init = function() {
            var notify = this;
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
    
                var notification = new Notification(settings.title, settings.options);
                
                notification.onclose = function() {
                    if (typeof settings.options.onClose == 'function') { 
                        settings.options.onClose();
                    }
                };
        
                notification.onclick = function(){
                    if (typeof settings.options.onClick == 'function') { 
                        settings.options.onClick();
                    }
                };
        
                notification.onerror  = function(){
                    if (typeof settings.options.onError  == 'function') { 
                        settings.options.onError();
                    }
                };
    
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission(function(permission) {
                if (permission === "granted") {
                    notify.init();
                }
        
                });
            }
    
        };
    
        this.init();
        return this;
    };

    let share = async function(options) {
        if (typeof navigator === 'undefined' || !navigator.share) {
            throw this.unavailable('Share API not available in this browser');
        }
        await navigator.share({
            title: options.title,
            text: options.text,
            url: options.url,
        });
        return {};
    };

    let statusBar = function() {
        
        var _supported = null; // set to null so we can check first time

        function isSupported () {
            // if not checked before, run check
            if (_supported === null) {
                var viewMan = window.UI.ViewManagement;
                _supported = viewMan.StatusBar && viewMan.StatusBar.getForCurrentView;
            }
            return _supported;
        }

        function getViewStatusBar () {
            if (!isSupported()) {
                throw new Error('Status bar is not supported');
            }
            return window.UI.ViewManagement.StatusBar.getForCurrentView();
        }

        function hexToRgb (hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result
                ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                }
                : null;
        }

        return {
            _ready: function (win, fail) {
                if (isSupported()) {
                    var statusBar = getViewStatusBar();
                    win(statusBar.occludedRect.height !== 0);
                }
            },
            overlaysWebView: function () {
                // not supported
            },

            styleDefault: function () {
                // dark text ( to be used on a light background )
                if (isSupported()) {
                    getViewStatusBar().foregroundColor = { a: 0, r: 0, g: 0, b: 0 };
                }
            },

            styleLightContent: function () {
                // light text ( to be used on a dark background )
                if (isSupported()) {
                    getViewStatusBar().foregroundColor = { a: 0, r: 255, g: 255, b: 255 };
                }
            },

            styleBlackTranslucent: function () {
                // #88000000 ? Apple says to use lightContent instead
                return module.exports.styleLightContent();
            },

            styleBlackOpaque: function () {
                // #FF000000 ? Apple says to use lightContent instead
                return module.exports.styleLightContent();
            },

            backgroundColorByHexString: function (win, fail, args) {
                var rgb = hexToRgb(args[0]);
                if (isSupported()) {
                    var statusBar = getViewStatusBar();
                    statusBar.backgroundColor = { a: 0, r: rgb.r, g: rgb.g, b: rgb.b };
                    statusBar.backgroundOpacity = 1;
                }
            },

            show: function (win, fail) {
                // added support check so no error thrown, when calling this method
                if (isSupported()) {
                    getViewStatusBar().showAsync().done(win, fail);
                }
            },

            hide: function (win, fail) {
                // added support check so no error thrown, when calling this method
                if (isSupported()) {
                    getViewStatusBar().hideAsync().done(win, fail);
                }
            }
        };
    };
    
    var mode = document.documentMode || 0;
    var msie = /MSIE/.test(navigator.userAgent);
    var lameIE = msie && (/MSIE (6|7|8)\.0/.test(navigator.userAgent) || mode < 9);

    /**
     * Chainable method for converting elements into rich media.
     *
     * @param options
     * @param callback fn invoked for each matched element before conversion
     * @param callback fn invoked for each matched element after conversion
     */
    $.fn.pdfviewer = function(options, f1, f2) {
        let selector=options.selector;
        if (options == 'undo') {
            return selector.each(function() {
                var $this = $(this);
                var html = $this.data('media.origHTML');
                if (html)
                    $this.replaceWith(html);
            });
        }
        
        return selector.each(function() {
            
            if (typeof options == 'function') {
                f2 = f1;
                f1 = options;
                options = {};
            }
            var o = getPdfSettings(this, options);
            // pre-conversion callback, passes original element and fully populated options
            if (typeof f1 == 'function') f1(this, o);

            var r = getTypesRegExp();
            var m = r.exec(o.src.toLowerCase()) || [''];
            var fn;

            if (o.type)
                m[0] = o.type;
            else
                m.shift();

            for (var i=0; i < m.length; i++) {
                fn = m[i].toLowerCase();
                if (isDigit(fn[0])) fn = 'fn' + fn; // fns can't begin with numbers
                if (!$.fn.pdfviewer[fn])
                    continue;  // unrecognized media type
                // normalize autoplay settings
                var player = $.fn.pdfviewer[fn+'_player'];
                if (!o.params) o.params = {};
                if (player) {
                    var num = player.autoplayAttr == 'autostart';
                    o.params[player.autoplayAttr || 'autoplay'] = num ? (o.autoplay ? 1 : 0) : o.autoplay ? true : false;
                }
                var $div = $.fn.pdfviewer[fn](this, o);

                $div.css('backgroundColor', o.bgColor).width(o.width);
                
                if (o.canUndo) {
                    var $temp = $('<div></div>').append(this);
                    $div.data('media.origHTML', $temp.html()); // store original markup
                }
                
                // post-conversion callback, passes original element, new div element and fully populated options
                if (typeof f2 == 'function') f2(this, $div[0], o, player.name);
                break;
            }
        });

    };


    // global defautls; override as needed
    $.fn.pdfviewer.defaults = {
        selector:$('a.media'),
        standards:  true,       // use object tags only (no embeds for non-IE browsers)
        canUndo:    true,       // tells plugin to store the original markup so it can be reverted via: $(sel).mediaUndo()
        width:		400,
        height:		400,
        autoplay:	0,			// normalized cross-player setting
        bgColor:	'#ffffff',	// background color
        attrs:		{},			// added to object and embed elements as attrs
        flvKeyName: 'file',		// key used for object src param (thanks to Andrea Ercolino)
        expressInstaller: null,	// src for express installer
    };

    // Media Players; think twice before overriding
    $.fn.pdfviewer.defaults.players = {
        // special cases
        img: {
            name:  'img',
            title: 'Image',
            types: 'gif,png,jpg'
        },
        iframe: {
            name:  'iframe',
            types: 'html,pdf'
        }
    };

    var counter = 1;
    for (var player in $.fn.pdfviewer.defaults.players) {
        var types = $.fn.pdfviewer.defaults.players[player].types;
        $.each(types.split(','), function(i,o) {
            if (isDigit(o[0])) o = 'fn' + o;
            $.fn.pdfviewer[o] = $.fn.pdfviewer[player] = getGenerator(player);
            $.fn.pdfviewer[o+'_player'] = $.fn.pdfviewer.defaults.players[player];
        });
    }

    function getTypesRegExp() {
        var types = '';
        for (var player in $.fn.pdfviewer.defaults.players) {
            if (types.length) types += ',';
            types += $.fn.pdfviewer.defaults.players[player].types;
        }
        return new RegExp('\\.(' + types.replace(/,/ig,'|') + ')\\b');
    }

    function getGenerator(player) {
        return function(el, options) {
            return pdfgenerate(el, options, player);
        };
    }

    function isDigit(c) {
        return '0123456789'.indexOf(c) > -1;
        
    }

    // flatten all possible options: global defaults, meta, option obj
    function getPdfSettings(el, options) {
        options = options || {};
        var a, n;
        var $el = $(el);
        var cls = el.className || '';
        // support metadata plugin (v1.0 and v2.0)
        var meta = $.metadata ? $el.metadata() : $.meta ? $el.data() : {};
        meta = meta || {};
        var w = meta.width  || parseInt(((cls.match(/\bw:(\d+)/)||[])[1]||0),10) || parseInt(((cls.match(/\bwidth:(\d+)/)||[])[1]||0),10);
        var h = meta.height || parseInt(((cls.match(/\bh:(\d+)/)||[])[1]||0),10) || parseInt(((cls.match(/\bheight:(\d+)/)||[])[1]||0),10);

        if (w) meta.width = w;
        if (h) meta.height = h;
        if (cls) meta.cls = cls;
        
        // crank html5 style data attributes
        var dataName = 'data-';
        for (var i=0; i < el.attributes.length; i++) {
            a = el.attributes[i];
            n = $.trim(a.name);
            var index = n.indexOf(dataName);
            if (index === 0) {
                n = n.substring(dataName.length);
                meta[n] = a.value;
            }
        }

        a = $.fn.pdfviewer.defaults;
        var b = options;
        var c = meta;

        var p = { params: { bgColor: options.bgColor || $.fn.pdfviewer.defaults.bgColor } };
        var opts = $.extend({}, a, b, c);
        $.each(['attrs','params','flashvars','silverlight'], function(i,o) {
            opts[o] = $.extend({}, p[o] || {}, a[o] || {}, b[o] || {}, c[o] || {});
        });

        if (typeof opts.caption == 'undefined') opts.caption = $el.text();

        // make sure we have a source!
        opts.src = opts.src || $el.attr('href') || $el.attr('src') || 'unknown';
        return opts;
    }

    //
    // generate object/embed markup
    //
    function pdfgenerate(el, opts, player) {
        var $el = $(el);
        var o = $.fn.pdfviewer.defaults.players[player];
        var a, key, v;

        if (player == 'iframe') {
            o = $('<iframe' + ' width="' + opts.width + '" height="' + opts.height + '" >');
            o.attr('src', opts.src);
            o.css('backgroundColor', o.bgColor);
        }
        else if (player == 'img') {
            o = $('<img>');
            o.attr('src', opts.src);
            if (opts.width)
                o.attr('width', opts.width);
            if (opts.height)
                o.attr('height', opts.height);
            o.css('backgroundColor', o.bgColor);
        }
        // convert element to div
        var id = el.id ? (' id="'+el.id+'"') : '';
        var cls = opts.cls ? (' class="' + opts.cls + '"') : '';
        var $div = $('<div' + id + cls + '>');
        $el.after($div).remove();
        if (lameIE || player == 'iframe' || player == 'img')
            $div.append(o);
        else
            $div.html(a.join(''));
        
        if (opts.caption) 
            $('<div>').appendTo($div).html(opts.caption);
        return $div;
    }

    let Hozap = {};
    let _init = () => {
        Hozap.userAgent = navigator.userAgent;
        Hozap.alert = alert;
        Hozap.prompt = prompt;
        Hozap.confirm = confirm;
        Hozap.easyNotify=$.fn.easyNotify;
        Hozap.share=share;
        Hozap.statusbar=new statusBar();
        Hozap.device=new Device();
        Hozap.filesystem=new FilesystemWeb();
        Hozap.getUserAgent=getUserAgent;
        Hozap.Directory=Directory;
        Hozap.Encoding=Encoding;
        Hozap.getLocation=getLocation;
        Hozap.pdfviewer=$.fn.pdfviewer;
        Hozap.actionsheet=new ActionSheetWeb();
       
        /* 
        Hozap.camera = camera; */
    }
    _init();
    window.Hozap=Hozap;
})(jQuery);