let init = () => {
    let gotDeviceId = () => {
        //You'll receive json data here
        //Example - {"deviceId":"abcd"}
        alert('{"deviceId":"abcd"}');
    };
    //Call this method whenever device id is required
    //In return gotDeviceId() function will be called with json encoded data
   // Flutter.postMessage("getDeviceId")
    return (
        <div className="container">
            <h2>Welcome to Hozap Js</h2>
            <hr />
            <div className="btn"><a onClick={gotDeviceId} id="alert">Get device</a></div>
        </div>
    );
};
  


  
export default init;