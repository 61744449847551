import React from 'react';
import $ from 'jquery'; 
import './Hozapjs/Hozapjs.js';
import './../node_modules/jquery-confirm/dist/jquery-confirm.min.css'
import myImg from './logo512.png'

let filesystem=window.Hozap.filesystem;
const Device=window.Hozap.device;

  
const Hozapcomp = () => {
	const showActionSheet = async () => {
		console.log('here')
		const result = await window.Hozap.actionsheet.showActions({
		  title: 'Photo Options',
		  message: 'Select an option to perform',
		  options: [
			{
			  title: 'Upload',
			},
			{
			  title: 'Share',
			},
			{
			  title: 'Remove',
			  style: window.Hozap.actionsheet.ActionSheetButtonStyle.Destructive,
			},
		  ],
		});
	  
		console.log('Action Sheet result:', result);
	  };
	let apires = "World";
	
	const setStatusBarStyleDark = async () => {
		console.log(window.Hozap.statusbar.styleDefault())
		await window.Hozap.statusbar.styleDefault();
	};

	let openDialog= async()=> {
		let value= await window.Hozap.alert({
			title: 'Hello',
			message: 'Hello World',
		});
		console.log(value)
	}

	let openConfirm= async()=> {
		let value= await window.Hozap.confirm({
			title: 'Hello',
			message: 'Hello World',
		});
		console.log(value)
	}


	let openPrompt= async()=> {
		//$.confirm("Hello world?");
		const { value, cancelled } = await window.Hozap.prompt({
			title: 'Prompt',
			message: 'Add your input here',
		});
		console.log('Name:', value);
  		console.log('Cancelled:', cancelled);
	
	}

	let notify=()=>{
		var options = {
			title:"Noti Title",
			options: {
			  body: "Notification Msg",
			  icon: myImg,
			  lang: 'en-US',
			  onClick: openDialog
			}
		  };
		  console.log(options);
		  window.Hozap.easyNotify(options);
	}

	let shareWeb=()=>{
		window.Hozap.share({
			title: 'See cool stuff',
			text: 'Really awesome thing you need to see right meow',
			url: 'http://ionicframework.com/'
		});
	}

	let getDeviceDetails= async ()=>{
		let devicedetails=await Device.getDeviceInfo();
		const info = await Device.getBatteryInfo();
		const id = await Device.getId();
		const languageCode = await Device.getLanguageCode();
		console.log('id',id);
		console.log('languageCode',languageCode);
  		console.log('info',info);
		console.log('Device',devicedetails);
	}

	let getUserAgent=()=>{
		alert(window.Hozap.getUserAgent());
	}

	let showLocation=()=>{
		window.Hozap.getLocation(function(position){
			alert(position.coords.latitude +" "+position.coords.latitude);
		});
	}

	
	const logDeviceInfo = async () => {
		const info = await Device.getDeviceInfo();
		console.log(info);
	};
	
	const logBatteryInfo = async () => {
		const info = await Device.getBatteryInfo();
		console.log(info);
	};

	const writeSecretFile = async () => {
		let res=await filesystem.writeFile({
		  path: 'secrets/text.txt',
		  data: "This is a test",
		  directory: window.Hozap.Directory.Documents,
		  encoding: window.Hozap.Encoding.UTF8,
		});
		
	};

	const deleteSecretFile = async () => {
		let res=await filesystem.deleteFile({
		  path: 'secrets/text.txt',
		  directory: window.Hozap.Directory.Documents,
		});
		console.log('Response:', res);
	};

	const readSecretFile = async () => {
		const contents = await filesystem.readFile({
		  path: 'secrets/text.txt',
		  directory: window.Hozap.Directory.Documents,
		  encoding: window.Hozap.Encoding.UTF8,
		});
		console.log('secrets:', contents);
	};

	/* const setStorageItem=async()=>{
		let result=await window.Hozap.setItem("gender","male");
		console.log(result);
	}
	setStorageItem();
	const getStorageItem=async()=>{
		await window.Hozap.getItem("gender",function(err,value){

			console.log(value);
		});
	}

	const deleteStorageItem=async()=>{
		await window.Hozap.deleteItem("gender",function(err,value){
			console.log(value);
		});
	} */

	const readFilePath = async () => {
		// Here's an example of reading a file with a full file path. Use this to
		// read binary data (base64 encoded) from plugins that return File URIs, such as
		// the Camera.
		const contents = await filesystem.readFile({
			path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt'
		});
		console.log('data:', contents);
  	};
	
	document.addEventListener("deviceready", onDeviceReady, false);
	function onDeviceReady() {
		document.addEventListener("backbutton", onBackKeyDown, false);
	}
	  
	function onBackKeyDown(e){
		e.preventDefault();
		alert("back  button pressed");
		navigator.app.exitApp();
	}

	let requestCameraPermission = () => {
		//Call this method for requesting camera permissions
		window.Flutter.postMessage("getCameraPermissions");
	};
	
	let requestNotificationPersmission = () => {
		//Call this method for requesting notfications permissions
		window.Flutter.postMessage("getNotifcationPermissions");
	};
	
	let requestGalleryPermissions = () => {
		//Call this method for requesting gallery permissions
		window.Flutter.postMessage("getGalleryPermissions");
	};
	
	let requestLocationPermissions = () => {
		//Call this method for requesting location permissions
		window.Flutter.postMessage("getLocationPermission");
	};
	
	let onBackPressed = () => {
		//This method is called when back button is pressed
	};

	let gotDeviceId = (json) => {
		//You'll receive json data here
		//Example - {"deviceId":"abcd"}
		alert(json);
	};
	window.gotDeviceId = (json) => { alert(json); }
	let init = () => {
		window.Flutter.postMessage("getDeviceId");
	}
	
	let showPdfFile=()=>{
		window.Hozap.pdfviewer({selector:$('a.media'),width:500,height:400});
	}
	/*   */
	return (
		<div className="container">
			<h2>Welcome to Hozap Js</h2>
			<hr />
			<div className="btn"><a onClick={openDialog} id="alert">Native Alert</a></div>
			<div className="btn"><a onClick={openConfirm} id="confirm">Native Confirm</a></div>
			<div className="btn"><a onClick={openPrompt} id="malert">Native Prompt</a></div>
			<div className="btn"><a onClick={notify} id="malert">Notify</a></div>
			<div className="btn"><a onClick={shareWeb} id="sharealert">Share</a></div>
			<div className="btn"><a onClick={getDeviceDetails} id="device">Get Device Details</a></div>
			<div className="btn"><a onClick={getUserAgent} id="userAgent">Get User Agent</a></div>
			<div className="btn"><a onClick={showLocation} id="location">Show Location</a></div>
			<div className="btn"><a onClick={showActionSheet} id="statusbar">Action Sheet</a></div>
			
			<div className="row">
				<div className='col-md-12'>
					<hr />
					<h4>Filesystem</h4>
					<div className="btn"><a onClick={writeSecretFile}>Write File</a></div>
					<div className="btn"><a onClick={readSecretFile}>Read File</a></div>
					<div className="btn"><a onClick={deleteSecretFile}>Delete File</a></div>
					
				</div>

				<div className='col-md-12'>
					<hr />
					<h4>Others</h4>
					<div className="btn"><a onClick={requestLocationPermissions}>Req Location Permission</a></div>
					<div className="btn"><a onClick={requestGalleryPermissions}>Req Gallery Permission</a></div>
					<div className="btn"><a onClick={requestNotificationPersmission}>Req Notify Permission</a></div>
					<div className="btn"><a onClick={requestCameraPermission}>Req Camera Permission</a></div>

					<div className="btn"><a onClick={init}>Get Device ID</a></div>
					<hr/>
				</div>
				<div className='col-md-12'>
					<h4>PDF Viewer</h4>
					<div className="btn"><a onClick={showPdfFile}>View PDF</a></div>
					<a className="media" href="guice.pdf"></a> 
				</div>
			</div>
		</div>
	);
}

export default Hozapcomp;