import React from 'react';
import Hozap from './Hozap';
import Flutter from './Fluttergetdevice';

import { Routes, Route} from 'react-router-dom';

const App = () => {
  return (
    <Routes>
    	<Route path="/"  element={<Hozap/>} />
    	<Route path="/flutter"  element={<Flutter/>} />

    </Routes>
  );
}

export default App;
